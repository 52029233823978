<template>
  <b-overlay rounded="sm" :show="loading">
    <AuthWrapper>
      <template #heading>
        <SecurityLogo />
      </template>

      <template #card>
        <div class="">
          <div class="text-center">
            <h1>MFA Recovery Codes </h1>
          </div>
          <div class="">
            <p class="m-0 p-0 text-center">
              Kindly save codes for future usage
            </p>
          </div>
        </div>
        <div class="pt-2 d-flex justify-content-around align-items-center ">
          <div>
            <div v-for="(code, i) in recovery_codes.slice(0, recovery_codes.length / 2)" :key="i">
              <div class="d-flex align-items-center m-1">
                <small class="m-0 p-0 mr-1">{{ i + 1 }}</small>
                <h2 class="text-primary m-0 p-0">
                  {{ code }}
                </h2>
              </div>
            </div>
          </div>
          |
          <div>
            <div v-for="(code, i) in recovery_codes.slice(recovery_codes.length / 2)" :key="i">
              <div class="d-flex align-items-center m-1">
                <small class="m-0 p-0 mr-1">{{ i + 1 + 5 }}</small>
                <h2 class="text-primary m-0 p-0">
                  {{ code }}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-1">
          <div>

            <b-link :to="{ name: 'auth-update-profile-account'}">
              <b-button
                  class="py-1"
                  type="submit"
                  variant="primary"
                  block
              >
                Continue
              </b-button>
            </b-link>
          </div>

        </div>
      </template>

    </AuthWrapper>
  </b-overlay>
</template>

<script>
import SecurityLogo from "@/pages/auth/shared-components/SecurityLogo.vue";
import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";
import {
  BCol,
  BImg,
  BRow,
  BLink,
  BForm,
  BAlert,
  BButton,
  BOverlay,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormCheckbox,
  BInputGroupAppend
} from "bootstrap-vue";
import BottomDesign from '@/assets/svg/BottomDesign.svg';
import { required } from "@core/utils/validations/validations";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Rectangles from "@/pages/auth/shared-components/rectangles.vue";

export default {
  components: {
    SecurityLogo,
    AuthWrapper,
    BottomDesign,
    Rectangles,
    BOverlay,
    BAlert,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    // BAlert,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      loading: false,
      recovery_codes: [],
      // validation rules
      required
    }
  },
  computed: {
  },
  created(){
    this.retrieveRecoveryCodes();
  },
  methods: {
    async retrieveRecoveryCodes() {
      const { recovery_codes } = this.$route.params;
      if (recovery_codes) {
        this.recovery_codes = recovery_codes;
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';


</style>
